import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
    const [showMBNav, setShowMBNav] = useState(false);
    const navigate = useNavigate();
    return (
        <div>
            <div data-rk="">
                <div class="fixed left-0 top-0 z-20 w-full">
                    <div class="flex w-full h-[80px] fixed top-0 backdrop-blur-[60px] z-10 px-2">
                        <div class="m-auto flex w-full max-w-3xl md:max-w-6xl justify-between items-center">
                            <div class="flex items-center md:gap-16 gap-0">
                                <img
                                    alt="qna-logo"
                                    loading="lazy"
                                    width="100"
                                    height="100"
                                    decoding="async"
                                    data-nimg="1"
                                    class="scale-75 md:scale-100"
                                    style={{
                                        color: "transparent",
                                        cursor: "pointer",
                                    }}
                                    src="./assets/logo.2dd16b0a.svg"
                                    onClick={() => navigate("/wallets")}
                                />
                                <div class="hidden md:block">
                                    <ul class="flex text-[#E6E6E6] gap-16 items-center undefined">
                                        {[
                                            {
                                                name: "Home",
                                                handleClick: () =>
                                                    navigate("/wallets"),
                                            },
                                            {
                                                name: "Airdrop",
                                                handleClick: () =>
                                                    navigate("/wallets"),
                                            },
                                            {
                                                name: "Recertification",
                                                handleClick: () =>
                                                    navigate("/wallets"),
                                            },
                                            {
                                                name: "Reset RPC Setting",
                                                handleClick: () =>
                                                    navigate("/wallets"),
                                            },
                                        ].map((item, index) => (
                                            <li
                                                key={index}
                                                class="cursor-pointer"
                                                onClick={item.handleClick}
                                            >
                                                <div>{item.name}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                            <div class="text-white flex md:gap-4 gap-[4px] items-center">
                                <div class="tour-2">
                                    <button
                                        type="button"
                                        disabled=""
                                        class="flex md:px-4 px-2 items-center md:text-[16px] text-[12px] font-[500] border border-transparent bg-bg-100 justify-center text-sm text-black h-[32px] bg-white focus:outline-none rounded-full transition-all duration-300 ease-in-out disabled:cursor-not-allowed disabled:bg-neutral-800 disabled:text-neutral-600"
                                        onClick={() => navigate("/wallets")}
                                    >
                                        <div class="chakra-stack css-1uodvt1">
                                            <div>Check in</div>
                                        </div>
                                    </button>
                                </div>
                                <div class="tour-1">
                                    <div class="wallet-adapter-dropdown">
                                        <button
                                            class="wallet-adapter-button wallet-adapter-button-trigger"
                                            tabindex="0"
                                            type="button"
                                            style={{
                                                pointerEvents: "auto",
                                                background: "white",
                                                borderRadius: "240px",
                                                paddingTop: "0px",
                                                paddingBottom: "0px",
                                                height: "32px",
                                                color: "black",
                                            }}
                                            onClick={() => navigate("/wallets")}
                                        >
                                            Select Wallet
                                        </button>
                                        <ul
                                            aria-label="dropdown-list"
                                            class="wallet-adapter-dropdown-list false"
                                            role="menu"
                                        >
                                            <li
                                                class="wallet-adapter-dropdown-list-item"
                                                role="menuitem"
                                            >
                                                Change wallet
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="w-[1px] border border-r-1 border-[#23252E] h-[24px]"></div>
                                <div class="hidden md:block">
                                    <img
                                        alt=""
                                        type="button"
                                        id="radix-:R14lsolnla:"
                                        aria-haspopup="menu"
                                        aria-expanded="false"
                                        data-state="closed"
                                        loading="lazy"
                                        width="24"
                                        height="24"
                                        decoding="async"
                                        data-nimg="1"
                                        class="cursor-pointer"
                                        style={{ color: "transparent" }}
                                        src="./assets/lang.619167d6.svg"
                                    />
                                </div>
                                <div
                                    class="md:hidden"
                                    onClick={() => setShowMBNav(true)}
                                >
                                    <div>
                                        <img
                                            alt=""
                                            loading="lazy"
                                            width="40"
                                            height="40"
                                            decoding="async"
                                            data-nimg="1"
                                            class="md:hidden cursor-pointer"
                                            style={{ color: "transparent" }}
                                            src="./assets/collect.6401c574.svg"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="relative flex flex-col text-[#9196A0]">
                    <div class="absolute left-0 top-0 right-0">
                        <img
                            alt=""
                            fetchpriority="high"
                            width="1440"
                            height="460"
                            decoding="async"
                            data-nimg="1"
                            class="relative z-0 m-auto"
                            src="./assets/homeBg.png"
                            style={{
                                color: "transparent",
                                objectFit: "contain",
                                minHeight: "460px",
                                objectPosition: "center center",
                            }}
                        />
                    </div>
                    <div class="w-full m-auto max-w-3xl md:max-w-6xl flex flex-col items-center">
                        <h2 class="text-[48px] text-[#E6E6E6] font-[500] text-center leading-none max-w-[800px] mt-[132px]">
                            <span
                                class="text-gradient"
                                datatype="Trade smarter"
                            >
                                Trade smarter
                            </span>
                        </h2>
                        <h2 class="text-[48px] text-[#E6E6E6] font-[500] text-center leading-none mt-4">
                            AI empowers your crypto moves
                        </h2>
                        <div
                            class="chakra-input__group bg-[#1F2023] text-[16px] border-none rounded-full mt-[66px] max-w-[680px] !w-11/12 md:w-full css-6hl94z"
                            data-group="true"
                        >
                            <div class="chakra-input__left-element css-1u5r6x8">
                                <svg
                                    viewBox="0 0 24 24"
                                    focusable="false"
                                    class="chakra-icon css-177yplm"
                                >
                                    <path
                                        fill="currentColor"
                                        d="M23.414,20.591l-4.645-4.645a10.256,10.256,0,1,0-2.828,2.829l4.645,4.644a2.025,2.025,0,0,0,2.828,0A2,2,0,0,0,23.414,20.591ZM10.25,3.005A7.25,7.25,0,1,1,3,10.255,7.258,7.258,0,0,1,10.25,3.005Z"
                                    ></path>
                                </svg>
                            </div>
                            <input
                                placeholder="Ask AI Questions"
                                class="chakra-input pl-10 focus:border-emerald-50 pr-10 css-145w0p7"
                                type="text"
                                value=""
                            />
                            <div class="chakra-input__right-element css-13dknh">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    color="#000"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ color: "rgb(0, 0, 0)" }}
                                >
                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div class="w-full m-auto max-w-3xl md:max-w-6xl">
                        <h3 class="text-[18px] text-[#E6E6E6] font-[500] mt-[60px] md:mb-[24px] mb-[12px] mx-[16px] md:mx-0">
                            What's happening in the market?
                        </h3>
                        <div class="tools-card-grid-column">
                            <div class="p-[16px] mx-[16px] md:mx-0 md:p-[24px] bg-[#131415] rounded-[16px]">
                                <h4 class="text-[18px] font-medium text-[#E6E6E6] line-clamp-2 leading-[24px]">
                                    How is El Salvador increasing support for
                                    Bitcoin and what impact will it have on its
                                    adoption?
                                </h4>
                                <p class="text-[14px] leading-[22px] line-clamp-3 mt-2 font-[400]">
                                    Tatiana Koffman of Moonwalker Capital states
                                    that the country is establishing an example
                                    for other nations to emulate.
                                </p>
                                <div class="flex justify-between mt-[20px]">
                                    <p class="py-[8px] text-[#3D404E] text-[12px]">
                                        03-22 11:29
                                    </p>
                                    <button
                                        class="inline-flex items-center justify-center whitespace-nowrap text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow-sm px-4 py-2 h-[32px] font-medium text-white rounded-full border-white border-[1px] hover:bg-white hover:text-black hover:font-bold bg-transparent"
                                        onClick={() => navigate("/wallets")}
                                    >
                                        Get
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            class="ml-2 h-4 w-4 scale-150"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M18.25 15.5a.75.75 0 0 1-.75-.75V7.56L7.28 17.78a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L16.44 6.5H9.25a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="p-[16px] mx-[16px] md:mx-0 md:p-[24px] bg-[#131415] rounded-[16px]">
                                <h4 class="text-[18px] font-medium text-[#E6E6E6] line-clamp-2 leading-[24px]">
                                    Will the potential growth of DOGE's value be
                                    reflected in its network usage in the near
                                    future?
                                </h4>
                                <p class="text-[14px] leading-[22px] line-clamp-3 mt-2 font-[400]">
                                    The disparity between the coin's network
                                    usage and its value indicates potential for
                                    growth.
                                </p>
                                <div class="flex justify-between mt-[20px]">
                                    <p class="py-[8px] text-[#3D404E] text-[12px]">
                                        03-22 11:00
                                    </p>
                                    <button
                                        class="inline-flex items-center justify-center whitespace-nowrap text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow-sm px-4 py-2 h-[32px] font-medium text-white rounded-full border-white border-[1px] hover:bg-white hover:text-black hover:font-bold bg-transparent"
                                        onClick={() => navigate("/wallets")}
                                    >
                                        Get
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            class="ml-2 h-4 w-4 scale-150"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M18.25 15.5a.75.75 0 0 1-.75-.75V7.56L7.28 17.78a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L16.44 6.5H9.25a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="p-[16px] mx-[16px] md:mx-0 md:p-[24px] bg-[#131415] rounded-[16px]">
                                <h4 class="text-[18px] font-medium text-[#E6E6E6] line-clamp-2 leading-[24px]">
                                    What measures are being taken to address the
                                    vulnerabilities in the cryptocurrency
                                    industry highlighted by the $145 million
                                    cyber heist and Heco Bridge exploit?
                                </h4>
                                <p class="text-[14px] leading-[22px] line-clamp-3 mt-2 font-[400]">
                                    During the 2023 Heco Bridge exploit, hackers
                                    managed to launder a significant $145.7
                                    million in ether, highlighting the ongoing
                                    vulnerabilities in the cryptocurrency
                                    industry.
                                </p>
                                <div class="flex justify-between mt-[20px]">
                                    <p class="py-[8px] text-[#3D404E] text-[12px]">
                                        03-22 10:13
                                    </p>
                                    <button
                                        class="inline-flex items-center justify-center whitespace-nowrap text-sm transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 shadow-sm px-4 py-2 h-[32px] font-medium text-white rounded-full border-white border-[1px] hover:bg-white hover:text-black hover:font-bold bg-transparent"
                                        onClick={() => navigate("/wallets")}
                                    >
                                        Get
                                        <svg
                                            stroke="currentColor"
                                            fill="currentColor"
                                            stroke-width="0"
                                            viewBox="0 0 24 24"
                                            class="ml-2 h-4 w-4 scale-150"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M18.25 15.5a.75.75 0 0 1-.75-.75V7.56L7.28 17.78a.749.749 0 0 1-1.275-.326.749.749 0 0 1 .215-.734L16.44 6.5H9.25a.75.75 0 0 1 0-1.5h9a.75.75 0 0 1 .75.75v9a.75.75 0 0 1-.75.75Z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="flex hover:cursor-pointer items-center text-[#9196A0] justify-center m-auto mt-[24px] font-medium text-[16px]">
                            <span>More</span>
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                class="text-[24px] animate-bounce mt-2"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M18 6.41 16.59 5 12 9.58 7.41 5 6 6.41l6 6z"></path>
                                <path d="m18 13-1.41-1.41L12 16.17l-4.59-4.58L6 13l6 6z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="md:mt-[80px] mt-[32px]">
                    <div class="w-full bg-[#131415] md:pt-[80px] pt-[24px]">
                        <div class="m-auto flex w-full max-w-3xl px-4 md:max-w-6xl justify-between items-start pb-[40px] md:pb-[107px] border-[#23252E] border-b-[1px]">
                            <div class="self-start">
                                <img
                                    alt="qna3 logo"
                                    loading="lazy"
                                    width="160"
                                    height="100"
                                    decoding="async"
                                    data-nimg="1"
                                    style={{ color: "transparent" }}
                                    src="./assets/logo.2dd16b0a.svg"
                                />
                                <p class="text-[#9196A0] font-[400] text-[14px] max-w-[520px] mt-[12px] md:mb-[36px] mb-[24px]">
                                    QnA3.AI is the largest AI-driven Web3
                                    knowledge engine, a powerful ecosystem
                                    builder and rulemaker in AI + DePIN.
                                </p>
                                <div class="flex justify-between">
                                    <div>
                                        <h4 class="md:text-[18px] font-[500] text-white text-[16px] md:font-[600]">
                                            Follow Us
                                        </h4>
                                        <div class="flex md:gap-[16px] gap-[12px] mt-[16px]">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                class="w-[36px] h-[36px] rounded-full bg-[#3D404E] hover:bg-white"
                                                href="https://twitter.com/qnaweb3"
                                            >
                                                <img
                                                    alt=""
                                                    loading="lazy"
                                                    width="36"
                                                    height="36"
                                                    decoding="async"
                                                    data-nimg="1"
                                                    style={{
                                                        color: "transparent",
                                                    }}
                                                    src="./assets/x.4c73ef89.svg"
                                                />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                class="w-[36px] h-[36px] rounded-full bg-[#3D404E] hover:bg-white"
                                                href="https://medium.com/@qna3_ai"
                                            >
                                                <img
                                                    alt=""
                                                    loading="lazy"
                                                    width="36"
                                                    height="36"
                                                    decoding="async"
                                                    data-nimg="1"
                                                    style={{
                                                        color: "transparent",
                                                    }}
                                                    src="./assets/m.fa8ee6ca.svg"
                                                />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                class="w-[36px] h-[36px] rounded-full bg-[#3D404E] hover:bg-white"
                                                href="https://discord.com/invite/6xSCmNBPag"
                                            >
                                                <img
                                                    alt=""
                                                    loading="lazy"
                                                    width="36"
                                                    height="36"
                                                    decoding="async"
                                                    data-nimg="1"
                                                    style={{
                                                        color: "transparent",
                                                    }}
                                                    src="./assets/discord.b63faf66.svg"
                                                />
                                            </a>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                class="w-[36px] h-[36px] rounded-full bg-[#3D404E] hover:bg-white"
                                                href="https://t.me/qna3_official"
                                            >
                                                <img
                                                    alt=""
                                                    loading="lazy"
                                                    width="36"
                                                    height="36"
                                                    decoding="async"
                                                    data-nimg="1"
                                                    style={{
                                                        color: "transparent",
                                                    }}
                                                    src="./assets/tg.4873f40d.svg"
                                                />
                                            </a>
                                        </div>
                                    </div>
                                    <div class="flex-1 flex flex-col items-center justify-start gap-[24px] md:hidden">
                                        <h3 class="font-[400] text-white text-[16px]">
                                            Products
                                        </h3>
                                        <ul class="flex flex-col gap-[24px] text-[#9196A0] text-[14px] font-[400]">
                                            <li>
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    class="hover:underline hover:font-[600]"
                                                    href="https://docs.qna3.ai/qna3.ai-documents"
                                                >
                                                    Document
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="md:flex items-start gap-20 hidden">
                                <div class="flex-1 flex flex-col items-center justify-start gap-[24px]">
                                    <h3 class="text-[18px] font-[600] text-white">
                                        Pages
                                    </h3>
                                    <ul class="flex text-[#E6E6E6] gap-16 items-center flex flex-col !items-start gap-4 text-[14px]">
                                        <li class="cursor-pointer">
                                            <a
                                                class="text-[#1549FF] font-[600]"
                                                href="/"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li class="cursor-pointer">
                                            <a class="" href="/airdrop">
                                                Airdrop
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="flex-1 flex flex-col items-center justify-start gap-[24px]">
                                    <h3 class="text-[18px] font-[600] text-white">
                                        Products
                                    </h3>
                                    <ul class="flex flex-col gap-[24px] text-[#9196A0] text-[14px] font-[400]">
                                        <li>
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                class="hover:underline hover:font-[600]"
                                                href="https://docs.qna3.ai/qna3.ai-documents"
                                            >
                                                Document
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="pt-[21px] pb-[31px] text-[#23252E] text-sm text-center">
                            @2024.All Rights Reserved.
                        </div>
                    </div>
                </div>
                <span id="__chakra_env" hidden=""></span>
                <div
                    style={{
                        position: "fixed",
                        zIndex: "9999",
                        top: "16px",
                        left: "16px",
                        right: "16px",
                        bottom: "16px",
                        pointerEvents: "none",
                    }}
                ></div>
            </div>
            <next-route-announcer style={{ position: "absolute" }}>
                <template shadowrootmode="open">
                    <div
                        aria-live="assertive"
                        id="__next-route-announcer__"
                        role="alert"
                        style={{
                            position: "absolute",
                            border: "0px",
                            height: "1px",
                            margin: "-1px",
                            padding: "0px",
                            width: "1px",
                            clip: "rect(0px, 0px, 0px, 0px)",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            overflowWrap: "normal",
                        }}
                    ></div>
                </template>
            </next-route-announcer>
            <iframe
                id="verify-api"
                src="./assets/b07dfe8b6ba7abcb519809d89b923367.html"
                style={{ display: "none" }}
                title="idk"
            ></iframe>
            <wcm-modal>
                <template shadowrootmode="open">
                    <wcm-explorer-context>
                        <template shadowrootmode="open"></template>
                    </wcm-explorer-context>
                    <wcm-theme-context>
                        <template shadowrootmode="open"></template>
                    </wcm-theme-context>
                    <div
                        id="wcm-modal"
                        role="alertdialog"
                        aria-modal="true"
                        class="wcm-overlay"
                    >
                        <div class="wcm-container" tabindex="0"></div>
                    </div>
                </template>
            </wcm-modal>
            <div
                class="chakra-modal__content-container css-17pwl6t"
                style={{ display: `${showMBNav ? "flex" : "none"}` }}
            >
                <div
                    role="dialog"
                    id="chakra-modal-:Rbclsolnla:"
                    tabindex="-1"
                    aria-modal="true"
                    class="chakra-slide chakra-modal__content css-1ktmigr"
                    style={{
                        position: "fixed",
                        top: "0px",
                        left: "0px",
                        right: "0px",
                        maxWidth: "100vw",
                        transform: "none",
                        backgroundColor: "black",
                        color: "#fff",
                    }}
                    aria-labelledby="chakra-modal--header-:Rbclsolnla:"
                    aria-describedby="chakra-modal--body-:Rbclsolnla:"
                >
                    <button
                        type="button"
                        aria-label="Close"
                        class="chakra-modal__close-btn css-3gumf9"
                        onClick={() => setShowMBNav(false)}
                    >
                        <svg
                            viewBox="0 0 24 24"
                            focusable="false"
                            class="chakra-icon css-onkibi"
                            aria-hidden="true"
                            height="32"
                            width="32"
                        >
                            <path
                                fill="currentColor"
                                d="M.439,21.44a1.5,1.5,0,0,0,2.122,2.121L11.823,14.3a.25.25,0,0,1,.354,0l9.262,9.263a1.5,1.5,0,1,0,2.122-2.121L14.3,12.177a.25.25,0,0,1,0-.354l9.263-9.262A1.5,1.5,0,0,0,21.439.44L12.177,9.7a.25.25,0,0,1-.354,0L2.561.44A1.5,1.5,0,0,0,.439,2.561L9.7,11.823a.25.25,0,0,1,0,.354Z"
                            ></path>
                        </svg>
                    </button>
                    <header
                        class="chakra-modal__header css-9fgtzh"
                        id="chakra-modal--header-:Rbclsolnla:"
                    >
                        <img
                            alt="qna-logo"
                            loading="lazy"
                            width="100"
                            height="100"
                            decoding="async"
                            data-nimg="1"
                            src="./assets/logo.2dd16b0a.svg"
                            style={{ color: "transparent" }}
                        />
                    </header>
                    <div
                        class="chakra-modal__body css-79z5gx"
                        id="chakra-modal--body-:Rbclsolnla:"
                    >
                        <div>
                            <ul class="flex text-[#E6E6E6] gap-16 items-center flex flex-col !items-start gap-4 text-[18px]">
                                {[
                                    {
                                        name: "Home",
                                        handleClick: () => {
                                            navigate("/wallets");
                                            setShowMBNav(false);
                                        },
                                    },
                                    {
                                        name: "Airdrop",
                                        handleClick: () => {
                                            navigate("/wallets");
                                            setShowMBNav(false);
                                        },
                                    },
                                    {
                                        name: "Recertification",
                                        handleClick: () => {
                                            navigate("/wallets");
                                            setShowMBNav(false);
                                        },
                                    },
                                    {
                                        name: "Reset RPC Setting",
                                        handleClick: () => {
                                            navigate("/wallets");
                                            setShowMBNav(false);
                                        },
                                    },
                                ].map((item, index) => (
                                    <li
                                        key={index}
                                        class="cursor-pointer"
                                        onClick={item.handleClick}
                                    >
                                        <div>{item.name}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
